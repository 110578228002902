.marker {
    background-image: url('../../public/sign.png');
    background-size: cover;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
}

.marker-positive {
    background-image: url('../../public/plus.png');
    background-size: cover;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
}

.marker-descriptive {
    background-image: url('../../public/information.png');
    background-size: cover;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    cursor: pointer;
}

.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}

.login-content {
    max-width: 500px;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.login-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-login {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.login-container {
    display: flex;
    height: 100vh;
}


.login-image-section {
    flex: 1.7 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 80%;
    height: 100%;
}

.login-image-section img {
    width: 80%;
    height: auto;
}


.login-form-section {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-message {
    color: red;
    margin-bottom: 15px;
    font-size: 0.9em;
}